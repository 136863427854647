/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

// https://angular.io/guide/router

const routes: Routes = [
  {path: 'simulation', loadChildren: () => import('./modules/simulation/simulation.module').then(m => m.SimulationModule), canActivate: [ AuthGuard ]},
  {path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [ AuthGuard ]},
  {path: 'debriefer', loadChildren: () => import('./modules/debriefer/debriefer.module').then(m => m.DebrieferModule), canActivate: [ AuthGuard ]},
  {path: 'portal', loadChildren: () => import('./modules/portal/portal.module').then(m => m.PortalModule), canActivate: [ AuthGuard ]},
  {path: 'state', loadChildren: () => import('./modules/state/state.module').then(m => m.StateModule), canActivate: [ AuthGuard ]},
  {path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)},
  {path: 'tools/sat', loadChildren: () => import('./modules/tools/sat/sat.module').then(m => m.SatModule), canActivate: [ AuthGuard ]},
  {path: 'tools/star', loadChildren: () => import('./modules/tools/star/star.module').then(m => m.StarModule), canActivate: [ AuthGuard ]},
  {path: 'tools/qswot', loadChildren: () => import('./modules/tools/qswot/qswot.module').then(m => m.QswotModule), canActivate: [ AuthGuard ]},
  {path: 'tools/chess', loadChildren: () => import('./modules/tools/chess/chess.module').then(m => m.ChessModule), canActivate: [ AuthGuard ]},
  {
    path: '**',
    redirectTo: 'portal',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
