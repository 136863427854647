/**
 * CentrXScenario.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable max-len */


export type InitiativePrefixes = 'CorpIni' | 'CustIni' | 'DI' | 'DMTool' | 'LeadIni' | 'IniCF' | 'IniCorpRep' | 'IniSoC' | 'IniTraining';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const InitiativePrefixes = {
    CorpIni: 'CorpIni' as InitiativePrefixes,
    CustIni: 'CustIni' as InitiativePrefixes,
    DI: 'DI' as InitiativePrefixes,
    DMTool: 'DMTool' as InitiativePrefixes,
    LeadIni: 'LeadIni' as InitiativePrefixes,
    IniCF: 'IniCF' as InitiativePrefixes,
    IniCorpRep: 'IniCorpRep' as InitiativePrefixes,
    IniSoC: 'IniSoC' as InitiativePrefixes,
    IniTraining: 'IniTraining' as InitiativePrefixes
};