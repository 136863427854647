import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
// import { BrowserModule } from '@angular/platform-browser';
// import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { AppRoutingModule } from './app-routing.module';
import { ConfigService, StringService } from './services';
import { AppComponent } from './app.component';

import { BUILD_NUMBER } from './variable';
import { environment } from 'src/environments/environment';
import { GlobalErrorHandler } from './helpers/global-error-handler';
import { MatButtonModule } from '@angular/material/button';

// import { DashboardModule } from './modules/dashboard/dashboard.module';
// import { DebrieferModule } from './modules/debriefer/debriefer.module';
// import { PortalModule } from './modules/portal/portal.module';
// import { WidgetsModule } from './widgets/widgets.module';
// import { StateModule } from './modules/state/state.module';
// import { SimulationModule } from './modules/simulation/simulation.module';
// import { LoginModule } from './modules/login/login.module';


// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const configFactory = (configService: ConfigService, stringService: StringService) =>
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  () => configService.loadConfig()
  ;

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,

    // BrowserModule,
    // FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,

    // *** REMOVED FOR LAZY LOADING **
    // SimulationModule,
    // DashboardModule,
    // PortalModule,
    // StateModule,
    // DebrieferModule,
    // LoginModule,
    // WidgetsModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: configFactory, deps: [ConfigService, StringService], multi: true },
    { provide: BUILD_NUMBER, useValue: environment.buildNumber },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
