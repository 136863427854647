/* eslint-disable max-len */
import { ChartDataset } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { ProductInfo } from 'src/app/generated/model/engine/productInfo';
import { ColorTheme } from 'src/color-theme';
import { IStringService } from '../interface/IStringService';

interface BubbleSource {
  x: number;
  y: number;
  valueInUse: number;
}

export class ChartElement {

  private readonly redColors = [ColorTheme.redFirm, ColorTheme.darkRedFirm, ColorTheme.lightRedFirm];
  private readonly blueColors = [ColorTheme.blueFirm, ColorTheme.darkBlueFirm, ColorTheme.lightBlueFirm];
  private readonly greenColors = [ColorTheme.greenFirm, ColorTheme.darkGreenFirm, ColorTheme.lightGreenFirm];
  private readonly orangeColors = [ColorTheme.orangeFirm, ColorTheme.darkOrangeFirm, ColorTheme.lightOrangeFirm];
  private readonly purpleColors = [ColorTheme.purpleFirm, ColorTheme.darkPurpleFirm, ColorTheme.lightPurpleFirm];
  private readonly yellowColors = [ColorTheme.yellowFirm, ColorTheme.darkYellowFirm, ColorTheme.lightYellowFirm];
  private readonly greyColors = [ColorTheme.greyFirm, ColorTheme.darkGreyFirm, ColorTheme.lightGreyFirm];
  private readonly team01Colors = [ColorTheme.team01, ColorTheme.team01d, ColorTheme.team01l];
  private readonly team02Colors = [ColorTheme.team02, ColorTheme.team02d, ColorTheme.team02l];
  private readonly team03Colors = [ColorTheme.team03, ColorTheme.team03d, ColorTheme.team03l];
  private readonly team04Colors = [ColorTheme.team04, ColorTheme.team04d, ColorTheme.team04l];
  private readonly team05Colors = [ColorTheme.team05, ColorTheme.team05d, ColorTheme.team05l];
  private readonly team06Colors = [ColorTheme.team06, ColorTheme.team06d, ColorTheme.team06l];
  private readonly team07Colors = [ColorTheme.team07, ColorTheme.team07d, ColorTheme.team07l];
  private readonly team08Colors = [ColorTheme.team08, ColorTheme.team08d, ColorTheme.team08l];
  private readonly team09Colors = [ColorTheme.team09, ColorTheme.team09d, ColorTheme.team09l];
  private readonly team10Colors = [ColorTheme.team10, ColorTheme.team10d, ColorTheme.team10l];
  private readonly team11Colors = [ColorTheme.team11, ColorTheme.team11d, ColorTheme.team11l];
  private readonly team12Colors = [ColorTheme.team12, ColorTheme.team12d, ColorTheme.team12l];
  private readonly team13Colors = [ColorTheme.team13, ColorTheme.team13d, ColorTheme.team13l];
  private readonly team14Colors = [ColorTheme.team14, ColorTheme.team14d, ColorTheme.team14l];

  private readonly blackColors = [ColorTheme.black, ColorTheme.grey, ColorTheme.grey];


  private redIndex = 0;
  private blueIndex = 0;
  private orangeIndex = 0;
  private purpleIndex = 0;
  private greenIndex = 0;
  private yellowIndex = 0;
  private greyIndex = 0;
  private blackIndex = 0;
  private t01Index = 0;
  private t02Index = 0;
  private t03Index = 0;
  private t04Index = 0;
  private t05Index = 0;
  private t06Index = 0;
  private t07Index = 0;
  private t08Index = 0;
  private t09Index = 0;
  private t10Index = 0;
  private t11Index = 0;
  private t12Index = 0;
  private t13Index = 0;
  private t14Index = 0;


  constructor(private stringService: IStringService) {}

  public initColorChart(valueToSet: number): void {
    this.redIndex = valueToSet;
    this.blueIndex = valueToSet;
    this.orangeIndex = valueToSet;
    this.purpleIndex = valueToSet;
    this.greenIndex = valueToSet;
    this.yellowIndex = valueToSet;
    this.blackIndex = valueToSet;
    this.greyIndex = valueToSet;
    this.t01Index = valueToSet;
    this.t02Index = valueToSet;
    this.t03Index = valueToSet;
    this.t04Index = valueToSet;
    this.t05Index = valueToSet;
    this.t06Index = valueToSet;
    this.t07Index = valueToSet;
    this.t08Index = valueToSet;
    this.t09Index = valueToSet;
    this.t10Index = valueToSet;
    this.t11Index = valueToSet;
    this.t12Index = valueToSet;
    this.t13Index = valueToSet;
    this.t14Index = valueToSet;
  }

  public createChartElement(data: number[], label: string, stack: string, color?: string, opacity: boolean = false, gradient: boolean = false): ChartDataset {
    let chartColorElement;
    if (color) { chartColorElement = color; }
    else {
      if (!gradient) { this.initColorChart(1); }
      chartColorElement = this.getColorOf(stack);
    }
    return {
      data,
      label: this.stringService.strings[label] ? this.stringService.strings[label] : label,
      backgroundColor: opacity ? chartColorElement.replace('1)', '0.2)') : chartColorElement,
      borderColor: chartColorElement,
      pointBackgroundColor: chartColorElement,
      pointHoverBackgroundColor: chartColorElement,
      pointHoverBorderColor: chartColorElement,
      hoverBackgroundColor: this.getColorOf('hover'),
      hoverBorderColor: chartColorElement,
      hoverBorderWidth: 4,
      stack
    };
  }

  public buildBubbleChart(chartDataset: ChartDataset[], productsRows: ProductInfo[], segmentsRows: string[], productsTable: number[][], segmentsDataTable: number[][], xValue: number, yValue: number, firm: string): ChartDataset[] {
    segmentsDataTable.forEach((segmentsTableItem: number[], index: number) => {
      const newValue: BubbleSource = {
        x: segmentsTableItem[xValue],
        y: segmentsTableItem[yValue],
        valueInUse: 0
      };
      this.initColorChart(0);
      const elementColor = this.getColorOf('Segments');
      this.initColorChart(2);
      const elementLightColor = this.getColorOf('Segments');
      const foundElement = chartDataset.find(element => element.label === this.stringService.strings[segmentsRows[index]]);
      if (foundElement) {
        foundElement.data.push(newValue);
      } else {
        const newElement: ChartDataset = {
          data: [newValue],
          pointRadius: 5,
          label: this.stringService.strings[segmentsRows[index]],
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          borderColor(context: any): string {
            const indexBorder = context.dataIndex;
            let colorTransparent: string = indexBorder === context.dataset.data.length - 1 ? ColorTheme.black : ColorTheme.grey;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent.toString();
          },
          hoverBorderColor: elementColor,
          hoverBackgroundColor: elementColor,
          stack: 'segments',
          pointStyle: 'crossRot',
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          pointBackgroundColor(context: Context): string {
            const indexPoint = context.dataIndex;
            let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent;
          },
          showLine: false,
          fill: false,
          borderWidth: 3
        };
        chartDataset.push(newElement);
      }
    });
    productsTable.forEach((productsTableItem: number[], index: number) => {
      const newValue: BubbleSource = {
        x: productsTableItem[xValue],
        y: productsTableItem[yValue],
        valueInUse: productsTableItem[productsTableItem.length - 1]
      };
      this.initColorChart(0);
      const elementColor = this.getColorOf(productsRows[index] ? productsRows[index].firm : '');
      this.initColorChart(2);
      const elementLightColor = this.getColorOf(productsRows[index] ? productsRows[index].firm : '');
      const foundElement = chartDataset.find(element => productsRows[index] && element.label === productsRows[index].product);
      if (firm === 'all' || productsRows[index].firm === firm) {
        if (foundElement) {
          foundElement?.data.push(newValue);
        } else {
          const newElement: ChartDataset = {
            data: [newValue],
            label: productsRows[index] ? productsRows[index].product : 'Error',
            stack: productsRows[index] ? productsRows[index].firm : 'Error',
            hoverBorderColor: elementColor,
            hoverBackgroundColor: elementColor,
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            pointBackgroundColor: 'transparent',
            pointRadius: (context: any) => context.raw.valueInUse !== 0 ? (2.3 + ((context.raw.valueInUse -1)* 15)) : 0,
            pointBorderWidth: (context: any) => 4.6 + (10 * (context.raw.valueInUse -1)),
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            pointBorderColor(context: any): string {
              const indexPoint = context.dataIndex;
              let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
              colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
              return colorTransparent;
            },
            // Hover settings:
            pointHoverBackgroundColor: 'transparent',
            pointHoverRadius: 1,
            pointHoverBorderWidth: (context: any) => 5.1 + (10 * (context.raw.valueInUse -1)),
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            pointHoverBorderColor(context: any): string {
              const indexPoint = context.dataIndex;
              let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
              colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
              return colorTransparent;
            },
            pointStyle: ('donut'),
            showLine: false,
            fill: false,
            borderWidth: 1
          };
          chartDataset.push(newElement);
        }
      }
    });
    return chartDataset;
  }

  // For e.g. Decisions > Communication > Perceptual Objectives
  public buildSegmentBubbleChart(chartDataset: ChartDataset[], segmentsRows: string[], segmentsDataTable: number[][], xValue: number, yValue: number): ChartDataset[] {
    segmentsDataTable.forEach((segmentsTableItem: number[], index: number) => {
      const newValue: BubbleSource = {
        x: segmentsTableItem[xValue],
        y: segmentsTableItem[yValue],
        valueInUse: 0
      };
      this.initColorChart(0);
      const elementColor = this.getColorOf(segmentsRows[index]);
      const elementColorTransparent = elementColor.replace('1)', '0.5)');
      const segmentColor = this.getColorOf('Segments');
      this.initColorChart(2);
      const elementLightColor = this.getColorOf(segmentsRows[index]);

      const foundElement = chartDataset.find(element => element.label === this.stringService.strings[segmentsRows[index]]);
      if (foundElement) {
        foundElement.data.push(newValue);
      } else {
        const newElement: ChartDataset = {
          data: [newValue],
          backgroundColor: elementColor,
          pointRadius: 5,
          label: this.stringService.strings[segmentsRows[index]],
          datalabels: { color: 'white', backgroundColor: elementColorTransparent},
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          borderColor(context: any): string {
            const indexBorder = context.dataIndex;
            let colorTransparent: string = indexBorder === context.dataset.data.length - 1 ? ColorTheme.black : ColorTheme.grey;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent.toString();
          },
          hoverBorderColor: segmentColor,
          hoverBackgroundColor: segmentColor,
          stack: 'segments',
          pointStyle: 'crossRot',
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          pointBackgroundColor(context: Context): string {
            const indexPoint = context.dataIndex;
            let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent;
          },
          showLine: false,
          fill: false,
          borderWidth: 3
        };
        chartDataset.push(newElement);
      }
    });

    return chartDataset;
  }

// For e.g. Decisions > Portfolio > R&D
  public buildTechnicalBubbleChart(
    chartDataset: ChartDataset[],
    productsRows: ProductInfo[],
    productsTable: number[][],
    segmentsRows: string[] = [],
    segmentsDataTable: number[][] = []): ChartDataset[] {

      segmentsDataTable?.forEach((segmentsTableItem: number[], index: number) => {
        const newValue: BubbleSource = {
          x: segmentsTableItem[0],
          y: segmentsTableItem[1],
          valueInUse: 0
        };
        this.initColorChart(0);
        const elementColor = this.getColorOf('Segments');
        this.initColorChart(2);
        const elementLightColor = this.getColorOf('Segments');
        const foundElement = chartDataset.find(element => element.label === this.stringService.strings[segmentsRows[index]]);
        if (foundElement) {
          foundElement.data.push(newValue);
        } else {
          const newElement: ChartDataset = {
            data: [newValue],
            pointRadius: 5,
            label: this.stringService.strings[segmentsRows[index]],
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            borderColor(context: any): string {
              const indexBorder = context.dataIndex;
              let colorTransparent: string = indexBorder === context.dataset.data.length - 1 ? ColorTheme.black : ColorTheme.grey;
              colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
              return colorTransparent.toString();
            },
            hoverBorderColor: elementColor,
            hoverBackgroundColor: elementColor,
            stack: 'segments',
            pointStyle: 'crossRot',
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            pointBackgroundColor(context: Context): string {
              const indexPoint = context.dataIndex;
              let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
              colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
              return colorTransparent;
            },
            showLine: false,
            fill: false,
            borderWidth: 3
          };
          chartDataset.push(newElement);
        }
      });

    productsTable?.forEach((productsTableItem: number[], index: number) => {
      const newValue: BubbleSource = {
        x: productsTableItem[0],
        y: productsTableItem[1],
        valueInUse: 0
      };
      this.initColorChart(0);
      const colorStr = productsRows[index] ?
        ((productsRows[index].firm === 'None' && productsRows[index].product?.startsWith('%'))? 'NoneGrey' : productsRows[index].firm) : '';
      const elementColor = this.getColorOf(colorStr);
      this.initColorChart(2);
      const elementLightColor = this.getColorOf(colorStr);
      const foundElement = chartDataset.find(element => productsRows[index] && element.label === productsRows[index].product);
      if (foundElement) {
        foundElement?.data.push(newValue);
      } else {
        const productName = productsRows[index] ? (productsRows[index]?.product??'') : 'Error';
        const stack = productsRows[index] ? productsRows[index].firm : 'Error';
        const newElement: ChartDataset = {
          data: [newValue],
          label: productName,
          stack,
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          borderColor(context: any): string {
            const indexBorder = context.dataIndex;
            return indexBorder === context.dataset.data.length - 1 ? 'black' : 'grey';
          },
          hoverBorderColor: elementColor,
          hoverBackgroundColor: elementColor,
          // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
          pointBackgroundColor(context: any): string {
            const indexPoint = context.dataIndex;
            let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
            colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
            return colorTransparent;
          },
          pointRadius: (stack === 'None')? 8 : 4,
          pointStyle: ('circle'),
          backgroundColor: elementColor,
          showLine: false,
          fill: false,
          borderWidth: (stack === 'None')? 0 : 1,
        };
        chartDataset.push(newElement);
      }
    });
    return chartDataset;
  }

  // For e.g. Decisions > Communication > Perceptual Objectives
  public buildProductBubbleChart(
    chartDataset: ChartDataset[],
    productsRows: ProductInfo[],
    productsTable: number[][],
    xValue: number,
    yValue: number,
    productName: string): ChartDataset[] {

    productsTable.forEach((productsTableItem: number[], index: number) => {
      const newValue: BubbleSource = {
        x: productsTableItem[xValue],
        y: productsTableItem[yValue],
        valueInUse: 0
      };
      this.initColorChart(0);
      const elementColor = this.getColorOf(productsRows[index] ? productsRows[index].firm : '');
      this.initColorChart(2);
      const elementLightColor = this.getColorOf(productsRows[index] ? productsRows[index].firm : '');
      const foundElement = chartDataset.find(element => productsRows[index] && element.label === productsRows[index].product);
      if (productsRows[index] && (productsRows[index].product === productName)) {
        if (foundElement) {
          foundElement?.data.push(newValue);
        } else {
          const newElement: ChartDataset = {
            data: [newValue],
            label: productsRows[index] ? productsRows[index].product : 'Error',
            stack: productsRows[index] ? productsRows[index].firm : 'Error',
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            borderColor(context: any): string {
              const indexBorder = context.dataIndex;
              return indexBorder === context.dataset.data.length - 1 ? 'black' : 'grey';
            },
            hoverBorderColor: elementColor,
            hoverBackgroundColor: elementColor,
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            pointBackgroundColor(context: any): string {
              const indexPoint = context.dataIndex;
              let colorTransparent = indexPoint === context.dataset.data.length - 1 ? elementColor : elementLightColor;
              colorTransparent = colorTransparent.replace('1)', (context.dataIndex + 1) / (context.dataset.data.length) + ')');
              return colorTransparent;
            },
            pointStyle: 'circle',
            pointRadius: 4,
            backgroundColor: elementColor,
            showLine: false,
            fill: false,
            borderWidth: 1
          };
          chartDataset.push(newElement);
        }
      }
    });
    return chartDataset;
  }

  public buildPeriodForecastLineChart(lineList: string[], chartData: (number|null)[][],
    selectedPeriod: number, colorList?: string[], forecast: boolean = true, gradient: boolean = false): ChartDataset[] {
    const chartToReturn: ChartDataset[] = [];
    this.initColorChart(0);
    // Previous periods to current
    for (let i = 0; i < lineList.length; i++) {
      const dataPoints: (number|null)[] = [];
      chartData.forEach((table, tableIndex) => {
        if (tableIndex <= selectedPeriod + 1) {
          dataPoints.push(table[i]);
        }
      });
      if (!gradient) { this.initColorChart(0); }
      const color = colorList ? this.getColorOf(colorList[i]) : this.getColorOf(lineList[i]);
      chartToReturn.push({
        data: dataPoints,
        label: this.stringService.strings[lineList[i]] ? this.stringService.strings[lineList[i]] : lineList[i],
        type: 'line',
        tension: 0.1,
        pointBackgroundColor: color,
        borderColor: color,
        datalabels: { color: 'white', backgroundColor: color}
      });
    }

    if (forecast) {
      this.initColorChart(0);
      // Current to forecast
      for (let i = 0; i < lineList.length; i++) {
        const dataPoints: (number|null)[] = [];
        chartData.forEach((table, tableIndex) => {
          if (tableIndex <= selectedPeriod) {
            dataPoints.push(null);
          } else {
            dataPoints.push(table[i]);
          }
        });
        const color = colorList ? this.getColorOf(colorList[i]) : this.getColorOf(lineList[i]);
        chartToReturn.push({
          data: dataPoints,
          label: this.stringService.strings[(lineList[i])],
          borderDash: [5],
          type: 'line',
          tension: 0.1,
          pointBackgroundColor: color,
          borderColor: color,
          datalabels: { color: 'white', backgroundColor: color}
        });
      }
    }

    return chartToReturn;
  }

  private getColorOf(stack: string): string {
    let colorToReturn: string;
    switch (stack) {
      case 'Team_1': {
        colorToReturn = this.team01Colors[this.t01Index];
        if (this.t01Index < this.team01Colors.length - 1) { this.t01Index++; }
        else { this.t01Index = 0; }
        break;
      }
      case 'Team_2': {
        colorToReturn = this.team02Colors[this.t02Index];
        if (this.t02Index < this.team02Colors.length - 1) { this.t02Index++; }
        else { this.t02Index = 0; }
        break;
      }
      case 'Team_3': {
        colorToReturn = this.team03Colors[this.t03Index];
        if (this.t03Index < this.team03Colors.length - 1) { this.t03Index++; }
        else { this.t03Index = 0; }
        break;
      }
      case 'Team_4': {
        colorToReturn = this.team04Colors[this.t04Index];
        if (this.t04Index < this.team04Colors.length - 1) { this.t04Index++; }
        else { this.t04Index = 0; }
        break;
      }
      case 'Team_5': {
        colorToReturn = this.team05Colors[this.t05Index];
        if (this.t05Index < this.team05Colors.length - 1) { this.t05Index++; }
        else { this.t05Index = 0; }
        break;
      }
      case 'Team_6': {
        colorToReturn = this.team06Colors[this.t06Index];
        if (this.t06Index < this.team06Colors.length - 1) { this.t06Index++; }
        else { this.t06Index = 0; }
        break;
      }
      case 'Team_7': {
        colorToReturn = this.team07Colors[this.t07Index];
        if (this.t07Index < this.team07Colors.length - 1) { this.t07Index++; }
        else { this.t07Index = 0; }
        break;
      }
      case 'Team_8': {
        colorToReturn = this.team08Colors[this.t08Index];
        if (this.t08Index < this.team08Colors.length - 1) { this.t08Index++; }
        else { this.t08Index = 0; }
        break;
      }
      case 'Team_9': {
        colorToReturn = this.team09Colors[this.t09Index];
        if (this.t09Index < this.team09Colors.length - 1) { this.t09Index++; }
        else { this.t09Index = 0; }
        break;
      }
      case 'Team_10': {
        colorToReturn = this.team10Colors[this.t10Index];
        if (this.t10Index < this.team10Colors.length - 1) { this.t10Index++; }
        else { this.t10Index = 0; }
        break;
      }
      case 'Team_11': {
        colorToReturn = this.team11Colors[this.t11Index];
        if (this.t11Index < this.team11Colors.length - 1) { this.t11Index++; }
        else { this.t11Index = 0; }
        break;
      }
      case 'Team_12': {
        colorToReturn = this.team12Colors[this.t12Index];
        if (this.t12Index < this.team12Colors.length - 1) { this.t12Index++; }
        else { this.t12Index = 0; }
        break;
      }
      case 'Team_13': {
        colorToReturn = this.team13Colors[this.t13Index];
        if (this.t13Index < this.team13Colors.length - 1) { this.t13Index++; }
        else { this.t13Index = 0; }
        break;
      }
      case 'Team_14': {
        colorToReturn = this.team14Colors[this.t14Index];
        if (this.t14Index < this.team14Colors.length - 1) { this.t14Index++; }
        else { this.t14Index = 0; }
        break;
      }
      case 'Firm_C': {
        colorToReturn = this.redColors[this.redIndex];
        if (this.redIndex < this.redColors.length - 1) { this.redIndex++; }
        else { this.redIndex = 0; }
        break;
      }
      case 'Firm_E': {
        colorToReturn = this.blueColors[this.blueIndex];
        if (this.blueIndex < this.blueColors.length - 1) { this.blueIndex++; }
        else { this.blueIndex = 0; }
        break;
      }
      case 'Firm_N': {
        colorToReturn = this.orangeColors[this.orangeIndex];
        if (this.orangeIndex < this.orangeColors.length - 1) { this.orangeIndex++; }
        else { this.orangeIndex = 0; }
        break;
      }
      case 'Firm_T': {
        colorToReturn = this.purpleColors[this.purpleIndex];
        if (this.purpleIndex < this.purpleColors.length - 1) { this.purpleIndex++; }
        else { this.purpleIndex = 0; }
        break;
      }
      case 'Firm_R': {
        colorToReturn = this.greenColors[this.greenIndex];
        if (this.greenIndex < this.greenColors.length - 1) { this.greenIndex++; }
        else { this.greenIndex = 0; }
        break;
      }
      case 'Firm_X': {
        colorToReturn = this.yellowColors[this.yellowIndex];
        if (this.yellowIndex < this.yellowColors.length - 1) { this.yellowIndex++; }
        else { this.yellowIndex = 0; }
        break;
      }
      case 'Seg_Energy': {
        return ColorTheme.greenEnergy;
      }
      case 'Seg_Appliances': {
        return ColorTheme.blueAppliances;
      }
      case 'Seg_Automotive': {
        return ColorTheme.redAutomobile;
      }
      case 'Seg_Industrial': {
        return ColorTheme.greyIndustrial;
      }
      case 'Seg_Aerospace': {
        return ColorTheme.yellowAerospace;
      }
      case 'Seg_Innovators': {
        return ColorTheme.greenInnovators;
      }
      case 'Seg_Followers': {
        return ColorTheme.yellowFollowers;
      }
      case 'Seg_Adopters': {
        return ColorTheme.blueAdopters;
      }
      case 'Segments': {
        colorToReturn = this.blackColors[this.blackIndex];
        if (this.blackIndex < this.blackColors.length - 1) { this.blackIndex++; }
        else { this.blackIndex = 0; }
        break;
      }
      case 'hover': {
        return ColorTheme.hover;
      }
      case 'None': {
        return ColorTheme.decision;
      }
      case 'NoneGrey': {
        return ColorTheme.grey;
      }
      case 'red': {
        colorToReturn = this.redColors[this.redIndex];
        if (this.redIndex < this.redColors.length - 1) { this.redIndex++; }
        else { this.redIndex = 0; }
        break;
      }
      case 'blue': {
        colorToReturn = this.blueColors[this.blueIndex];
        if (this.blueIndex < this.blueColors.length - 1) { this.blueIndex++; }
        else { this.blueIndex = 0; }
        break;
      }
      case 'orange': {
        colorToReturn = this.orangeColors[this.orangeIndex];
        if (this.orangeIndex < this.orangeColors.length - 1) { this.orangeIndex++; }
        else { this.orangeIndex = 0; }
        break;
      }
      case 'purple': {
        colorToReturn = this.purpleColors[this.purpleIndex];
        if (this.purpleIndex < this.purpleColors.length - 1) { this.purpleIndex++; }
        else { this.purpleIndex = 0; }
        break;
      }
      case 'green': {
        colorToReturn = this.greenColors[this.greenIndex];
        if (this.greenIndex < this.greenColors.length - 1) { this.greenIndex++; }
        else { this.greenIndex = 0; }
        break;
      }
      case 'yellow': {
        colorToReturn = this.yellowColors[this.yellowIndex];
        if (this.yellowIndex < this.yellowColors.length - 1) { this.yellowIndex++; }
        else { this.yellowIndex = 0; }
        break;
      }
      case 'grey': {
        colorToReturn = this.greyColors[this.greyIndex];
        if (this.greyIndex < this.greyColors.length - 1) { this.greyIndex++; }
        else { this.greyIndex = 0; }
        break;
      }
      case 'black': {
        colorToReturn = this.blackColors[this.blackIndex];
        if (this.blackIndex < this.blackColors.length - 1) { this.blackIndex++; }
        else { this.blackIndex = 0; }
        break;
      }
      default: {
        const color = stack as ColorTheme;
        if (color) {
          return color;
        }
        return ColorTheme.redFirm;
      }
    }
    return colorToReturn;
  }
}
