/* eslint-disable max-len */
import { Inject, Component, ChangeDetectionStrategy, OnDestroy, OnInit, ChangeDetectorRef, HostBinding } from '@angular/core';

import { AuthService, ConfigService, LogService, StringService } from './services';
import { BUILD_NUMBER } from './variable';

import { environment } from 'src/environments/environment';
import { envName } from 'src/environments/envName';
import { Observable, ReplaySubject, Subject, Subscription, combineLatest } from 'rxjs';
import { Role } from './enums/Role';
import { AccountApi } from './api/auth/account-api';
import { DisplayReason } from './widgets/presentational/splash-screen/DisplayReason';
import { ActivationEnd, ActivationStart, ChildActivationEnd, ChildActivationStart, GuardsCheckEnd, GuardsCheckStart, NavigationCancel, NavigationEnd, NavigationError, NavigationSkipped, NavigationStart, ResolveEnd, ResolveStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RoutesRecognized, Scroll } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class.isSplashDisplayed') isSplashDisplayed: boolean = false;

  readonly componentName = 'AppComponent';
  readonly DisplayReason = DisplayReason;
  readonly title = `${StringService.brandName} Simulations`;
  readonly companyName: string = StringService.companyName;
  readonly companyDomain: string = StringService.companyDomain;

  identityAddress: string;
  decisionAddress: string;
  engineAddress: string;
  portfolioAddress: string;
  scenarioAddress: string;
  seminarAddress: string;
  stateAddress: string;
  configType: string;
  version: string;
  canSeeLinks: boolean = false;
  reason: DisplayReason = DisplayReason.None;

  authenticated$: Observable<boolean>;
  numbers: number[] = [];
  watermark = '';

  private url$: Subject<string>;
  private url: string | undefined;

  private console = LogService.initialize(this.componentName);
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(BUILD_NUMBER) public buildNumber: string,
    private accountApi: AccountApi,
    private authService: AuthService,
    private configService: ConfigService,
    private router: Router,
    private changeDetector: ChangeDetectorRef) {

    this.console.log(`constructor()`);

    const { authBaseUrl, decisionBaseUrl, engineBaseUrl, portfolioBaseUrl, scenarioBaseUrl, seminarBaseUrl, stateBaseUrl, configType } = this.configService.config;

    this.identityAddress = authBaseUrl;
    this.decisionAddress = decisionBaseUrl;
    this.engineAddress = engineBaseUrl;
    this.portfolioAddress = portfolioBaseUrl;
    this.scenarioAddress = scenarioBaseUrl;
    this.seminarAddress = seminarBaseUrl;
    this.stateAddress = stateBaseUrl;
    this.configType = configType?.toUpperCase();
    this.version = environment.version;

    this.authenticated$ = this.authService.isLoggedIn$;
    this.url$ = new ReplaySubject(1);

    this.router.events.subscribe(
      (event: (NavigationStart | NavigationEnd | NavigationCancel | NavigationError | RoutesRecognized | GuardsCheckStart | GuardsCheckEnd | RouteConfigLoadStart | RouteConfigLoadEnd | ChildActivationStart | ChildActivationEnd | ActivationStart | ActivationEnd | Scroll | ResolveStart | ResolveEnd | NavigationSkipped)) => {
        if (event instanceof NavigationEnd) {
          const url = (event as NavigationEnd).url;
          this.console.warn(`router: ${url} `, event);

          if (!this.url) {
            this.url = url;
            this.url$.next(url);
          }
        }
      });

    this.watermark = configType;
    if (configType!=='local') {
      this.numbers = Array(1500).fill(0).map((x, i) => i + 1);
    }
  }

  ngOnInit(): void {
    this.console.log(`ngOnInit()`);

    this.subscriptions.push(
      combineLatest([this.authService.isLoggedIn$, this.url$])
        .subscribe(([isLogged, url]) => {
          if (isLogged) {
            const pos = url.indexOf('?');
            url = (pos >= 0) ? url.slice(0, pos) : url;
            this.console.log(`url: ${url}`);
            this.accountApi.connect(url).subscribe({
              next: user => {
                this.console.log(`getUser()`, user);
                // eslint-disable-next-line no-bitwise
                this.canSeeLinks = this.hasAnyRole(user.role, Role.Developer | Role.Administrator);
                this.changeDetector.detectChanges();
              },
              error: (e) => {
                console.error(`getUser failed`, e);

                const stringValue = window.localStorage.getItem('MaintenanceMode');

                if (stringValue && JSON.parse(stringValue)) {
                  this.reason = DisplayReason.Maintenance;
                } else {
                  this.reason = DisplayReason.Error503;
                }

                this.isSplashDisplayed = true;
                this.changeDetector.markForCheck();
              },
              complete: () => this.console.log(`getUser complete`)
            });
          }
        }));

    if ((environment.name !== envName.Production) && (environment.name !== envName.LocalProd)) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      document.getElementsByTagName('html')[0]['style'].background = 'rgba(231, 245, 238, 1)';
    }
  }

  ngOnDestroy(): void {
    this.console.log(`ngOnDestroy`);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  logoutButtonClicked(): void {
    this.console.log('logoutButtonClicked');
    this.authService.logout();
    window.location.reload();
  }

  public hasAnyRole(userRole: number, role: Role): boolean {
    // eslint-disable-next-line no-bitwise
    return (userRole & role) !== 0;
  }
}
