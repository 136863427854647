/**
 * CentrXScenario.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable max-len */


export type InsightKey = 'B1' | 'B2' | 'B3' | 'B4' | 'B5' | 'B6' | 'B7' | 'B8' | 'B9' | 'D1' | 'D2' | 'M1' | 'M2' | 'VD1' | 'VD2' | 'VD3' | 'VD4' | 'VD5' | 'VD6' | 'VD7' | 'VP1' | 'VP2' | 'VP3' | 'VP5' | 'VP6' | 'VP7' | 'S1' | 'Insight_1_1' | 'Insight_1_2' | 'Insight_1_3' | 'Insight_1_4' | 'Insight_1_5' | 'Insight_1_6' | 'Insight_1_7' | 'Insight_2_1' | 'Insight_2_2' | 'Insight_2_3' | 'Insight_2_4' | 'Insight_2_5' | 'Insight_2_6' | 'Insight_2_7' | 'Insight_3_1' | 'Insight_3_2' | 'Insight_3_3' | 'Insight_3_4' | 'Insight_3_5' | 'Insight_3_6' | 'Insight_3_7' | 'Insight_3_8' | 'Insight_3_9' | 'Insight_4_0';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const InsightKey = {
    B1: 'B1' as InsightKey,
    B2: 'B2' as InsightKey,
    B3: 'B3' as InsightKey,
    B4: 'B4' as InsightKey,
    B5: 'B5' as InsightKey,
    B6: 'B6' as InsightKey,
    B7: 'B7' as InsightKey,
    B8: 'B8' as InsightKey,
    B9: 'B9' as InsightKey,
    D1: 'D1' as InsightKey,
    D2: 'D2' as InsightKey,
    M1: 'M1' as InsightKey,
    M2: 'M2' as InsightKey,
    VD1: 'VD1' as InsightKey,
    VD2: 'VD2' as InsightKey,
    VD3: 'VD3' as InsightKey,
    VD4: 'VD4' as InsightKey,
    VD5: 'VD5' as InsightKey,
    VD6: 'VD6' as InsightKey,
    VD7: 'VD7' as InsightKey,
    VP1: 'VP1' as InsightKey,
    VP2: 'VP2' as InsightKey,
    VP3: 'VP3' as InsightKey,
    VP5: 'VP5' as InsightKey,
    VP6: 'VP6' as InsightKey,
    VP7: 'VP7' as InsightKey,
    S1: 'S1' as InsightKey,
    Insight11: 'Insight_1_1' as InsightKey,
    Insight12: 'Insight_1_2' as InsightKey,
    Insight13: 'Insight_1_3' as InsightKey,
    Insight14: 'Insight_1_4' as InsightKey,
    Insight15: 'Insight_1_5' as InsightKey,
    Insight16: 'Insight_1_6' as InsightKey,
    Insight17: 'Insight_1_7' as InsightKey,
    Insight21: 'Insight_2_1' as InsightKey,
    Insight22: 'Insight_2_2' as InsightKey,
    Insight23: 'Insight_2_3' as InsightKey,
    Insight24: 'Insight_2_4' as InsightKey,
    Insight25: 'Insight_2_5' as InsightKey,
    Insight26: 'Insight_2_6' as InsightKey,
    Insight27: 'Insight_2_7' as InsightKey,
    Insight31: 'Insight_3_1' as InsightKey,
    Insight32: 'Insight_3_2' as InsightKey,
    Insight33: 'Insight_3_3' as InsightKey,
    Insight34: 'Insight_3_4' as InsightKey,
    Insight35: 'Insight_3_5' as InsightKey,
    Insight36: 'Insight_3_6' as InsightKey,
    Insight37: 'Insight_3_7' as InsightKey,
    Insight38: 'Insight_3_8' as InsightKey,
    Insight39: 'Insight_3_9' as InsightKey,
    Insight40: 'Insight_4_0' as InsightKey
};