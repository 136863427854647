/* eslint-disable max-len */
import { Decision, DecisionKey } from '../generated/model/decision';
import { StringKey, MenuEntryKey, InsightKey, InitiativeKey } from '../generated/model/scenario';
import { IStringService } from '../interface/IStringService';
import 'src/extensions/string.extensions';

enum IniType {
  BrandLevel,
  CompanyLevel,
  Leadership,
  DMTool
}

export class DecisionString {
  constructor(private stringService: IStringService) { }

  // UTC format
  public decisionDateToString(decision: Decision): string {
    let date = (decision.when?.toLocaleString('en-US')??'').replace('T', ' ');
    date = date.substring(0, date.lastIndexOf('.'));

    return date;
  }

  public decisionWhatToString(decision: Decision): string {
    let stringDec = '?';
    if (decision && decision.what && decision.what.key) {
      const dec = decision.what.key;
      switch (dec) {
        case DecisionKey.ProductManagementRnDProjects:
          if (decision.what.boolValue) {
            const baseProject = this.displayProjectName(decision.what.stringValue);
            if (baseProject) {
              stringDec = this.stringService.strings[StringKey.DecisionsStringProjectLaunchedBased]
                .replace('{0}', this.displayProjectName(decision.what.projectId))
                .replace('{1}', this.displayProjectName(baseProject));
            } else {
              stringDec = this.stringService.strings[StringKey.DecisionsStringProjectLaunched]
                .replace('{0}', this.displayProjectName(decision.what.projectId));
            }
          } else {
            stringDec = this.stringService.strings[StringKey.DecisionsStringProjectCanceled]
              .replace('{0}', this.displayProjectName(decision.what.projectId));
          }
          break;
        case DecisionKey.ProductManagementRnDProjectsAllocatedBudget:
          return this.stringService.strings[StringKey.DecisionsStringAllocatedBudget]
            .replace('{0}', this.displayProjectName(decision.what.projectId))
            .replace('{1}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementRnDProjectsRequestedUnitBaseCost:
          return this.stringService.strings[StringKey.DecisionsStringRequestedUnitCost]
            .replace('{0}', this.displayProjectName(decision.what.projectId))
            .replace('{1}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementPortfolioProductMarketed:
          stringDec = decision.what.boolValue? this.stringService.strings[StringKey.DecisionsStringProductLaunched]
            : this.stringService.strings[StringKey.DecisionsStringProductRemoved];
          const product = (this.displayProductName(decision.what?.productId)??'') + (decision.what?.productId??'');
          stringDec = stringDec.replace('{0}', product);
          break;
        case DecisionKey.CommercialPricing:
          return this.stringService.strings[StringKey.DecisionsStringProductPrice].replace('{0}', this.displayProductName(decision.what?.productId)??'?')
            .replace('{1}', (decision.what.intValue??0).toString());
        case DecisionKey.OperationProductionLevel:
          return this.stringService.strings[StringKey.DecisionsStringProduction].replace('{0}', this.displayProductName(decision.what?.productId)??'?')
            .replace('{1}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementRnDProjectsDesignSupport:
          return this.stringService.strings[StringKey.DecisionsStringProjectSemantic]
            .replace('{0}', this.displayProjectName(decision.what.projectId))
            .replace('{1}', this.stringService.strings[StringKey.SemDesignSupport]).replace('{2}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementRnDProjectsLoad:
          return this.stringService.strings[StringKey.DecisionsStringProjectSemantic].replace('{0}',
            this.displayProjectName(decision.what.projectId))
            .replace('{1}', this.stringService.strings[StringKey.SemLoad]).replace('{2}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementRnDProjectsMTTF:
          return this.stringService.strings[StringKey.DecisionsStringProjectSemantic].replace('{0}',
            this.displayProjectName(decision.what.projectId))
            .replace('{1}', this.stringService.strings[StringKey.SemMTTF]).replace('{2}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementRnDProjectsOutflow:
          return this.stringService.strings[StringKey.DecisionsStringProjectSemantic].replace('{0}',
            this.displayProjectName(decision.what.projectId))
            .replace('{1}', this.stringService.strings[StringKey.SemOutflow]).replace('{2}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementRnDProjectsPrecision:
          return this.stringService.strings[StringKey.DecisionsStringProjectSemantic].replace('{0}',
            this.displayProjectName(decision.what.projectId))
            .replace('{1}', this.stringService.strings[StringKey.SemPrecision]).replace('{2}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementRnDProjectsSpeed:
          return this.stringService.strings[StringKey.DecisionsStringProjectSemantic].replace('{0}',
            this.displayProjectName(decision.what.projectId))
            .replace('{1}', this.stringService.strings[StringKey.SemSpeed]).replace('{2}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementRnDProjectsTechnicalSupport:
          return this.stringService.strings[StringKey.DecisionsStringProjectSemantic].replace('{0}',
            this.displayProjectName(decision.what.projectId))
            .replace('{1}', this.stringService.strings[StringKey.SemTechnicalSupport]).replace('{2}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementRnDProjectsTemperature:
          return this.stringService.strings[StringKey.DecisionsStringProjectSemantic].replace('{0}',
            this.displayProjectName(decision.what.projectId))
            .replace('{1}', this.stringService.strings[StringKey.SemTemperature]).replace('{2}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementRnDProjectsUptimeIndex:
          return this.stringService.strings[StringKey.DecisionsStringProjectSemantic].replace('{0}',
            this.displayProjectName(decision.what.projectId))
            .replace('{1}', this.stringService.strings[StringKey.SemUptime]).replace('{2}', (decision.what.intValue??0).toString());
        case DecisionKey.ProductManagementRnDProjectsVolume:
          return this.stringService.strings[StringKey.DecisionsStringProjectSemantic].replace('{0}',
            this.displayProjectName(decision.what.projectId))
            .replace('{1}', this.stringService.strings[StringKey.SemVolume]).replace('{2}', (decision.what.intValue??0).toString());

        case DecisionKey.CommercialCommunicationPerceptualObjectivesMDSAxis1:
          return this.stringService.strings[StringKey.DecisionsStringCommPerceptualAxis]
            .replace('{0}', this.displayProductName(decision.what?.productId)??'?').replace('{1}', '1')
            .replace('{2}', this.stringService.strings[(decision.what.stringValue??'?')]);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesMDSAxis2:
          return this.stringService.strings[StringKey.DecisionsStringCommPerceptualAxis]
            .replace('{0}', this.displayProductName(decision.what?.productId)??'?').replace('{1}', '2')
            .replace('{2}', this.stringService.strings[(decision.what.stringValue??'?')]);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesSemanticAxis1:
          return this.stringService.strings[StringKey.DecisionsStringCommPerceptualAxis]
            .replace('{0}', this.displayProductName(decision.what?.productId)??'?').replace('{1}', '1')
            .replace('{2}', this.stringService.strings[(decision.what.stringValue??'?')]);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesSemanticAxis2:
          return this.stringService.strings[StringKey.DecisionsStringCommPerceptualAxis]
            .replace('{0}', this.displayProductName(decision.what?.productId)??'?').replace('{1}', '2')
            .replace('{2}', this.stringService.strings[(decision.what.stringValue??'?')]);

        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetEconomy:
          return this.commPerceptualString(decision, StringKey.MDSEconomy);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetEfficacy:
          return this.commPerceptualString(decision, StringKey.MDSEfficacy);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetFlexibility:
          return this.commPerceptualString(decision, StringKey.MDSFlexibility);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetPerformance:
          return this.commPerceptualString(decision, StringKey.MDSPerformance);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetReliability:
          return this.commPerceptualString(decision, StringKey.MDSReliability);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetDesignSupport:
          return this.commPerceptualString(decision, StringKey.SemDesignSupport);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetLoad:
          return this.commPerceptualString(decision, StringKey.SemLoad);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetMTTF:
          return this.commPerceptualString(decision, StringKey.SemMTTF);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetOutflow:
          return this.commPerceptualString(decision, StringKey.SemOutflow);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetPrecision:
          return this.commPerceptualString(decision, StringKey.SemPrecision);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetPrice:
          return this.commPerceptualString(decision, StringKey.SemPrice);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetSpeed:
          return this.commPerceptualString(decision, StringKey.SemSpeed);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetTechnicalSupport:
          return this.commPerceptualString(decision, StringKey.SemTechnicalSupport);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetTemperature:
          return this.commPerceptualString(decision, StringKey.SemTemperature);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetUptimeIndex:
          return this.commPerceptualString(decision, StringKey.SemUptime);
        case DecisionKey.CommercialCommunicationPerceptualObjectivesTargetVolume:
          return this.commPerceptualString(decision, StringKey.SemVolume);

        case DecisionKey.CommercialCommunicationPerceptualObjectivesType:
          return (this.displayProductName(decision.what?.productId)??'?') + ' comm. perceptual type set to ' + this.stringService.strings[(decision.what.stringValue??'?')];
        case DecisionKey.CustomerDiscoveryCustomerExperienceJourney:
          return this.stringService.strings[StringKey.DecisionsStringCustomerExperience].replace('{0}',
            this.stringService.menuEntries[MenuEntryKey.DecisionsInsightsCustomerExperienceJourney]?.longTitle??'');
        case DecisionKey.CustomerDiscoveryCustomerValueProposition:
          return this.stringService.strings[StringKey.DecisionsStringCustomerExperience].replace('{0}',
            this.stringService.menuEntries[MenuEntryKey.DecisionsInsightsCustomerValue]?.longTitle??'');
        case DecisionKey.CustomerDiscoveryStatekeholdersDiscovery:
          return this.stringService.strings[StringKey.DecisionsStringCustomerExperience].replace('{0}',
            this.stringService.menuEntries[MenuEntryKey.DecisionsInsightsStakeholdersDiscovery]?.longTitle??'');
        case DecisionKey.CustomerDiscoveryValueChain:
          return this.stringService.strings[StringKey.DecisionsStringCustomerExperience].replace('{0}',
            this.stringService.menuEntries[MenuEntryKey.DecisionsInsightsValueChain]?.longTitle??'');
        case DecisionKey.CommercialCommunicationBudgetCommunicationBudget:
            return this.stringService.strings[StringKey.DecisionsStringCommBudget]
              .replace('{0}', this.displayProductName(decision.what?.productId)??'?').replace('{1}', (decision.what.intValue??0).toString());
        case DecisionKey.CommercialCommunicationBudgetCommunicationResearchBudget:
            return this.stringService.strings[StringKey.DecisionsStringCommResearchBudget]
              .replace('{0}', this.displayProductName(decision.what?.productId)??'?').replace('{1}', (decision.what.intValue??0).toString());

        case DecisionKey.CommercialCommunicationBudgetAdopters:
          return this.commSegmentString(decision, StringKey.SegAdopters);
        case DecisionKey.CommercialCommunicationBudgetAerospace:
          return this.commSegmentString(decision, StringKey.SegAerospace);
        case DecisionKey.CommercialCommunicationBudgetAppliances:
          return this.commSegmentString(decision, StringKey.SegAppliances);
        case DecisionKey.CommercialCommunicationBudgetAutomotive:
          return this.commSegmentString(decision, StringKey.SegAutomotive);
        case DecisionKey.CommercialCommunicationBudgetEnergy:
          return this.commSegmentString(decision, StringKey.SegEnergy);
        case DecisionKey.CommercialCommunicationBudgetFollowers:
          return this.commSegmentString(decision, StringKey.SegFollowers);
        case DecisionKey.CommercialCommunicationBudgetIndustrial:
          return this.commSegmentString(decision, StringKey.SegIndustrial);
        case DecisionKey.CommercialCommunicationBudgetInnovators:
          return this.commSegmentString(decision, StringKey.SegInnovators);

        case DecisionKey.CommercialSalesForceDirectDistribution:
          return this.salesForcesString(decision, StringKey.ChannelDirect, false);
        case DecisionKey.CommercialSalesForceGeneralLine:
          return this.salesForcesString(decision, StringKey.ChannelGeneral, false);
        case DecisionKey.CommercialSalesForceTechnicalSpecialists:
          return this.salesForcesString(decision, StringKey.ChannelTechnical, false);
        case DecisionKey.CommercialSalesForceHireFireDirectDistribution:
          return this.salesForcesString(decision, StringKey.ChannelDirect, true);
        case DecisionKey.CommercialSalesForceHireFireGeneralLine:
          return this.salesForcesString(decision, StringKey.ChannelGeneral, true);
        case DecisionKey.CommercialSalesForceHireFireTechnicalSpecialists:
          return this.salesForcesString(decision, StringKey.ChannelTechnical, true);

        case DecisionKey.ExperienceJourneyInitiativeB1:
          return this.insightString(decision, InsightKey.B1);
        case DecisionKey.ExperienceJourneyInitiativeB2:
          return this.insightString(decision, InsightKey.B2);
        case DecisionKey.ExperienceJourneyInitiativeB3:
          return this.insightString(decision, InsightKey.B3);
        case DecisionKey.ExperienceJourneyInitiativeB4:
          return this.insightString(decision, InsightKey.B4);
        case DecisionKey.ExperienceJourneyInitiativeB6:
          return this.insightString(decision, InsightKey.B6);
        case DecisionKey.ExperienceJourneyInitiativeVP2:
          return this.insightString(decision, InsightKey.VP2);
        case DecisionKey.ExperienceJourneyInitiativeVD1:
          return this.insightString(decision, InsightKey.VD1);
        case DecisionKey.ExperienceJourneyInitiativeVD2:
          return this.insightString(decision, InsightKey.VD2);
        case DecisionKey.StakeholdersDiscoveryInitiativeB5:
          return this.insightString(decision, InsightKey.B5);
        case DecisionKey.StakeholdersDiscoveryInitiativeVP7:
          return this.insightString(decision, InsightKey.VP7);
        case DecisionKey.StakeholdersDiscoveryInitiativeVD3:
          return this.insightString(decision, InsightKey.VD3);
        case DecisionKey.ValuePropositionInitiativeB7:
          return this.insightString(decision, InsightKey.B7);
        case DecisionKey.ValuePropositionInitiativeB9:
          return this.insightString(decision, InsightKey.B9);
        case DecisionKey.ValuePropositionInitiativeVP1:
          return this.insightString(decision, InsightKey.VP1);
        case DecisionKey.ValuePropositionInitiativeVP3:
          return this.insightString(decision, InsightKey.VP3);
        case DecisionKey.ValuePropositionInitiativeVP5:
          return this.insightString(decision, InsightKey.VP5);
        case DecisionKey.ValuePropositionInitiativeVD6:
          return this.insightString(decision, InsightKey.VD6);
        case DecisionKey.ValuePropositionInitiativeVD7:
          return this.insightString(decision, InsightKey.VD7);
        case DecisionKey.ValuePropositionInitiativeD1:
          return this.insightString(decision, InsightKey.D1);
        case DecisionKey.ValuePropositionInitiativeD2:
          return this.insightString(decision, InsightKey.D2);
        case DecisionKey.ValuePropositionInitiativeS1:
          return this.insightString(decision, InsightKey.S1);
        case DecisionKey.ValueChainInitiativeB8:
          return this.insightString(decision, InsightKey.B8);
        case DecisionKey.ValueChainInitiativeVP6:
          return this.insightString(decision, InsightKey.VP6);
        case DecisionKey.ValueChainInitiativeVD4:
          return this.insightString(decision, InsightKey.VD4);
        case DecisionKey.ValueChainInitiativeVD5:
          return this.insightString(decision, InsightKey.VD5);

        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesCustomerCentricitySetup:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniCustomerCentricity, true);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesCrossFunctionalSetup:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniCrossFunctional, true);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesSponsorMeetingsSetup:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniSponsorMeetings, true);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesCrowdSourcingSetup:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniCrowdSourcing, true);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesSixSigmaProgramSetup:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniSixSigmaProgram, true);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesCoDevelopmentCustomersSetup:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniCoDevelopmentCustomers, true);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesClosedLoopSetup:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniClosedLoop, true);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesRecyclingSetup:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniRecycling, true);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesBigDataAnalyticsSetup:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniBigDataAnalytics, true);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesHighGrowthSetup:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniHighGrowth, true);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesIso14001Setup:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniIso14001, true);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesCustomerCentricity:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniCustomerCentricity);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesCrossFunctional:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniCrossFunctional);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesSponsorMeetings:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniSponsorMeetings);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesCrowdSourcing:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniCrowdSourcing);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesSixSigmaProgram:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniSixSigmaProgram);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesCoDevelopmentCustomers:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniCoDevelopmentCustomers);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesClosedLoop:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniClosedLoop);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesRecycling:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniRecycling);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesBigDataAnalytics:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniBigDataAnalytics);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesHighGrowth:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniHighGrowth);
        case DecisionKey.CompanyAndBrandLevelCorporateInitiativesIso14001:
          return this.iniString(IniType.CompanyLevel, decision, null, InitiativeKey.CorpIniIso14001);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesImproveITSystemSetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniImproveITSystem, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesCustomerDiscoverySetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniCustomerDiscovery, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesDistributorsIncentivesSetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniDistributorsIncentives, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesCoDevelopmentSetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniCoDevelopment, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesGuaranteedDatesSetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniGuaranteedDates, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesNewInstallationSetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniNewInstallation, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesRemoteMonitoringSetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniRemoteMonitoring, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesCoBrandingSetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniCoBranding, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesPathwayGuideSetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniPathwayGuide, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesLeadUsersGroupSetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniLeadUsersGroup, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesUseCaseSetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniUseCase, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesCircularManufacturingSetup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniCircularManufacturing, true);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesImproveITSystem:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniImproveITSystem);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesCustomerDiscovery:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniCustomerDiscovery);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesDistributorsIncentives:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniDistributorsIncentives);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesCoDevelopment:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniCoDevelopment);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesGuaranteedDates:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniGuaranteedDates);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesNewInstallation:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniNewInstallation);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesRemoteMonitoring:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniRemoteMonitoring);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesCoBranding:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniCoBranding);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesPathwayGuide:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniPathwayGuide);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesLeadUsersGroup:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniLeadUsersGroup);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesUseCase:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniUseCase);
        case DecisionKey.CompanyAndBrandLevelCustomerInitiativesCircularManufacturing:
          return this.iniString(IniType.BrandLevel, decision, null, InitiativeKey.CustIniCircularManufacturing);

        case DecisionKey.CommercialMarketIntelligenceCompetitiveIntelligence:
          return this.marketStudyString(decision, StringKey.MSIntelligence);
        case DecisionKey.CommercialMarketIntelligenceCustomerPanel:
          return this.marketStudyString(decision, StringKey.MSCustomerPanel);
        case DecisionKey.CommercialMarketIntelligenceCustomerSurvey:
          return this.marketStudyString(decision, StringKey.MSCustomerSurvey);
        case DecisionKey.CommercialMarketIntelligenceDigital:
          return this.marketStudyString(decision, StringKey.MSDigital);
        case DecisionKey.CommercialMarketIntelligenceDistributionPanel:
          return this.marketStudyString(decision, StringKey.MSDistributionPanel);
        case DecisionKey.CommercialMarketIntelligenceDueDiligence:
          return this.marketStudyString(decision, StringKey.MSDueDiligence);
        case DecisionKey.CommercialMarketIntelligenceIPIntelligence:
          return this.marketStudyString(decision, StringKey.MSIPIntelligence);
        case DecisionKey.CommercialMarketIntelligenceMSITDiagnostic:
          return this.marketStudyString(decision, StringKey.MSITDiagnostic);
        case DecisionKey.CommercialMarketIntelligenceMarketForecast:
          return this.marketStudyString(decision, StringKey.MSForecast);
        case DecisionKey.CommercialMarketIntelligenceMultiDimensionalScales:
          return this.marketStudyString(decision, StringKey.MSMDS);
        case DecisionKey.CommercialMarketIntelligenceSemantic:
          return this.marketStudyString(decision, StringKey.MSSemantic);
        case DecisionKey.CommercialMarketIntelligenceStudyInvestigation:
          return this.marketStudyString(decision, StringKey.MSStudyInvestigation);

        case DecisionKey.OperationInitiativesCostReduction:
          return this.iniString(IniType.Leadership, decision, null, InitiativeKey.LeadIniLowCostManufacturing);
        case DecisionKey.OperationInitiativesFlexibleManufacturingProgram:
          return this.iniString(IniType.Leadership, decision, null, InitiativeKey.LeadIniFlexibleManufacturing);
        case DecisionKey.FinanceInitiativesCashFlowInitiative:
          return this.iniString(IniType.Leadership, decision, null, InitiativeKey.LeadIniCashFlowInitiative);
        case DecisionKey.HumanResourcesInitiativesEmployeeEngagement:
          return this.iniString(IniType.Leadership, decision, null, InitiativeKey.LeadIniEmployeeEngagement);
        case DecisionKey.HumanResourcesInitiativesLeadershipDevelopment:
          return this.iniString(IniType.Leadership, decision, null, InitiativeKey.LeadIniCultureChange);
        case DecisionKey.HumanResourcesInitiativesSalesTraining:
          return this.iniString(IniType.Leadership, decision, null, InitiativeKey.LeadIniSalesTraining);
        case DecisionKey.ProductManagementInitiativesOptimalSemLoad:
          return this.iniString(IniType.Leadership, decision, null, InitiativeKey.LeadIniOptimalSemLoad);

        case DecisionKey.CommercialDigitalMarketingAdoptersChannelSupport:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAdopters, InitiativeKey.DMToolChannelSupport);
        case DecisionKey.CommercialDigitalMarketingAdoptersContent:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAdopters, InitiativeKey.DMToolContent);
        case DecisionKey.CommercialDigitalMarketingAdoptersEmail:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAdopters, InitiativeKey.DMToolEmail);
        case DecisionKey.CommercialDigitalMarketingAdoptersIndustryMicrosite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAdopters, InitiativeKey.DMToolIndustryMicrosite);
        case DecisionKey.CommercialDigitalMarketingAdoptersInteractiveDesign:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAdopters, InitiativeKey.DMToolInteractiveDesign);
        case DecisionKey.CommercialDigitalMarketingAdoptersOnlineCommunities:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAdopters, InitiativeKey.DMToolOnlineCommunities);
        case DecisionKey.CommercialDigitalMarketingAdoptersSEM:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAdopters, InitiativeKey.DMToolSEM);
        case DecisionKey.CommercialDigitalMarketingAdoptersSocialMedia:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAdopters, InitiativeKey.DMToolSocialMedia);
        case DecisionKey.CommercialDigitalMarketingAdoptersSupportWebsite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAdopters, InitiativeKey.DMToolSupportWebsite);
        case DecisionKey.CommercialDigitalMarketingAerospaceChannelSupport:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAerospace, InitiativeKey.DMToolChannelSupport);
        case DecisionKey.CommercialDigitalMarketingAerospaceContent:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAerospace, InitiativeKey.DMToolContent);
        case DecisionKey.CommercialDigitalMarketingAerospaceEmail:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAerospace, InitiativeKey.DMToolEmail);
        case DecisionKey.CommercialDigitalMarketingAerospaceIndustryMicrosite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAerospace, InitiativeKey.DMToolIndustryMicrosite);
        case DecisionKey.CommercialDigitalMarketingAerospaceInteractiveDesign:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAerospace, InitiativeKey.DMToolInteractiveDesign);
        case DecisionKey.CommercialDigitalMarketingAerospaceOnlineCommunities:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAerospace, InitiativeKey.DMToolOnlineCommunities);
        case DecisionKey.CommercialDigitalMarketingAerospaceSEM:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAerospace, InitiativeKey.DMToolSEM);
        case DecisionKey.CommercialDigitalMarketingAerospaceSocialMedia:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAerospace, InitiativeKey.DMToolSocialMedia);
        case DecisionKey.CommercialDigitalMarketingAerospaceSupportWebsite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAerospace, InitiativeKey.DMToolSupportWebsite);
        case DecisionKey.CommercialDigitalMarketingAppliancesChannelSupport:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAppliances, InitiativeKey.DMToolChannelSupport);
        case DecisionKey.CommercialDigitalMarketingAppliancesContent:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAppliances, InitiativeKey.DMToolContent);
        case DecisionKey.CommercialDigitalMarketingAppliancesEmail:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAppliances, InitiativeKey.DMToolEmail);
        case DecisionKey.CommercialDigitalMarketingAppliancesIndustryMicrosite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAppliances, InitiativeKey.DMToolIndustryMicrosite);
        case DecisionKey.CommercialDigitalMarketingAppliancesInteractiveDesign:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAppliances, InitiativeKey.DMToolInteractiveDesign);
        case DecisionKey.CommercialDigitalMarketingAppliancesOnlineCommunities:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAppliances, InitiativeKey.DMToolOnlineCommunities);
        case DecisionKey.CommercialDigitalMarketingAppliancesSEM:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAppliances, InitiativeKey.DMToolSEM);
        case DecisionKey.CommercialDigitalMarketingAppliancesSocialMedia:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAppliances, InitiativeKey.DMToolSocialMedia);
        case DecisionKey.CommercialDigitalMarketingAppliancesSupportWebsite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAppliances, InitiativeKey.DMToolSupportWebsite);
        case DecisionKey.CommercialDigitalMarketingAutomotiveChannelSupport:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAutomotive, InitiativeKey.DMToolChannelSupport);
        case DecisionKey.CommercialDigitalMarketingAutomotiveContent:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAutomotive, InitiativeKey.DMToolContent);
        case DecisionKey.CommercialDigitalMarketingAutomotiveEmail:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAutomotive, InitiativeKey.DMToolEmail);
        case DecisionKey.CommercialDigitalMarketingAutomotiveIndustryMicrosite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAutomotive, InitiativeKey.DMToolIndustryMicrosite);
        case DecisionKey.CommercialDigitalMarketingAutomotiveInteractiveDesign:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAutomotive, InitiativeKey.DMToolInteractiveDesign);
        case DecisionKey.CommercialDigitalMarketingAutomotiveOnlineCommunities:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAutomotive, InitiativeKey.DMToolOnlineCommunities);
        case DecisionKey.CommercialDigitalMarketingAutomotiveSEM:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAutomotive, InitiativeKey.DMToolSEM);
        case DecisionKey.CommercialDigitalMarketingAutomotiveSocialMedia:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAutomotive, InitiativeKey.DMToolSocialMedia);
        case DecisionKey.CommercialDigitalMarketingAutomotiveSupportWebsite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegAutomotive, InitiativeKey.DMToolSupportWebsite);
        case DecisionKey.CommercialDigitalMarketingEnergyChannelSupport:
          return this.iniString(IniType.DMTool, decision, StringKey.SegEnergy, InitiativeKey.DMToolChannelSupport);
        case DecisionKey.CommercialDigitalMarketingEnergyContent:
          return this.iniString(IniType.DMTool, decision, StringKey.SegEnergy, InitiativeKey.DMToolContent);
        case DecisionKey.CommercialDigitalMarketingEnergyEmail:
          return this.iniString(IniType.DMTool, decision, StringKey.SegEnergy, InitiativeKey.DMToolEmail);
        case DecisionKey.CommercialDigitalMarketingEnergyIndustryMicrosite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegEnergy, InitiativeKey.DMToolIndustryMicrosite);
        case DecisionKey.CommercialDigitalMarketingEnergyInteractiveDesign:
          return this.iniString(IniType.DMTool, decision, StringKey.SegEnergy, InitiativeKey.DMToolInteractiveDesign);
        case DecisionKey.CommercialDigitalMarketingEnergyOnlineCommunities:
          return this.iniString(IniType.DMTool, decision, StringKey.SegEnergy, InitiativeKey.DMToolOnlineCommunities);
        case DecisionKey.CommercialDigitalMarketingEnergySEM:
          return this.iniString(IniType.DMTool, decision, StringKey.SegEnergy, InitiativeKey.DMToolSEM);
        case DecisionKey.CommercialDigitalMarketingEnergySocialMedia:
          return this.iniString(IniType.DMTool, decision, StringKey.SegEnergy, InitiativeKey.DMToolSocialMedia);
        case DecisionKey.CommercialDigitalMarketingEnergySupportWebsite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegEnergy, InitiativeKey.DMToolSupportWebsite);
        case DecisionKey.CommercialDigitalMarketingFollowersChannelSupport:
          return this.iniString(IniType.DMTool, decision, StringKey.SegFollowers, InitiativeKey.DMToolChannelSupport);
        case DecisionKey.CommercialDigitalMarketingFollowersContent:
          return this.iniString(IniType.DMTool, decision, StringKey.SegFollowers, InitiativeKey.DMToolContent);
        case DecisionKey.CommercialDigitalMarketingFollowersEmail:
          return this.iniString(IniType.DMTool, decision, StringKey.SegFollowers, InitiativeKey.DMToolEmail);
        case DecisionKey.CommercialDigitalMarketingFollowersIndustryMicrosite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegFollowers, InitiativeKey.DMToolIndustryMicrosite);
        case DecisionKey.CommercialDigitalMarketingFollowersInteractiveDesign:
          return this.iniString(IniType.DMTool, decision, StringKey.SegFollowers, InitiativeKey.DMToolInteractiveDesign);
        case DecisionKey.CommercialDigitalMarketingFollowersOnlineCommunities:
          return this.iniString(IniType.DMTool, decision, StringKey.SegFollowers, InitiativeKey.DMToolOnlineCommunities);
        case DecisionKey.CommercialDigitalMarketingFollowersSEM:
          return this.iniString(IniType.DMTool, decision, StringKey.SegFollowers, InitiativeKey.DMToolSEM);
        case DecisionKey.CommercialDigitalMarketingFollowersSocialMedia:
          return this.iniString(IniType.DMTool, decision, StringKey.SegFollowers, InitiativeKey.DMToolSocialMedia);
        case DecisionKey.CommercialDigitalMarketingFollowersSupportWebsite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegFollowers, InitiativeKey.DMToolSupportWebsite);
        case DecisionKey.CommercialDigitalMarketingIndustrialChannelSupport:
          return this.iniString(IniType.DMTool, decision, StringKey.SegIndustrial, InitiativeKey.DMToolChannelSupport);
        case DecisionKey.CommercialDigitalMarketingIndustrialContent:
          return this.iniString(IniType.DMTool, decision, StringKey.SegIndustrial, InitiativeKey.DMToolContent);
        case DecisionKey.CommercialDigitalMarketingIndustrialEmail:
          return this.iniString(IniType.DMTool, decision, StringKey.SegIndustrial, InitiativeKey.DMToolEmail);
        case DecisionKey.CommercialDigitalMarketingIndustrialIndustryMicrosite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegIndustrial, InitiativeKey.DMToolIndustryMicrosite);
        case DecisionKey.CommercialDigitalMarketingIndustrialInteractiveDesign:
          return this.iniString(IniType.DMTool, decision, StringKey.SegIndustrial, InitiativeKey.DMToolInteractiveDesign);
        case DecisionKey.CommercialDigitalMarketingIndustrialOnlineCommunities:
          return this.iniString(IniType.DMTool, decision, StringKey.SegIndustrial, InitiativeKey.DMToolOnlineCommunities);
        case DecisionKey.CommercialDigitalMarketingIndustrialSEM:
          return this.iniString(IniType.DMTool, decision, StringKey.SegIndustrial, InitiativeKey.DMToolSEM);
        case DecisionKey.CommercialDigitalMarketingIndustrialSocialMedia:
          return this.iniString(IniType.DMTool, decision, StringKey.SegIndustrial, InitiativeKey.DMToolSocialMedia);
        case DecisionKey.CommercialDigitalMarketingIndustrialSupportWebsite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegIndustrial, InitiativeKey.DMToolSupportWebsite);
        case DecisionKey.CommercialDigitalMarketingInnovatorsChannelSupport:
          return this.iniString(IniType.DMTool, decision, StringKey.SegInnovators, InitiativeKey.DMToolChannelSupport);
        case DecisionKey.CommercialDigitalMarketingInnovatorsContent:
          return this.iniString(IniType.DMTool, decision, StringKey.SegInnovators, InitiativeKey.DMToolContent);
        case DecisionKey.CommercialDigitalMarketingInnovatorsEmail:
          return this.iniString(IniType.DMTool, decision, StringKey.SegInnovators, InitiativeKey.DMToolEmail);
        case DecisionKey.CommercialDigitalMarketingInnovatorsIndustryMicrosite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegInnovators, InitiativeKey.DMToolIndustryMicrosite);
        case DecisionKey.CommercialDigitalMarketingInnovatorsInteractiveDesign:
          return this.iniString(IniType.DMTool, decision, StringKey.SegInnovators, InitiativeKey.DMToolInteractiveDesign);
        case DecisionKey.CommercialDigitalMarketingInnovatorsOnlineCommunities:
          return this.iniString(IniType.DMTool, decision, StringKey.SegInnovators, InitiativeKey.DMToolOnlineCommunities);
        case DecisionKey.CommercialDigitalMarketingInnovatorsSEM:
          return this.iniString(IniType.DMTool, decision, StringKey.SegInnovators, InitiativeKey.DMToolSEM);
        case DecisionKey.CommercialDigitalMarketingInnovatorsSocialMedia:
          return this.iniString(IniType.DMTool, decision, StringKey.SegInnovators, InitiativeKey.DMToolSocialMedia);
        case DecisionKey.CommercialDigitalMarketingInnovatorsSupportWebsite:
          return this.iniString(IniType.DMTool, decision, StringKey.SegInnovators, InitiativeKey.DMToolSupportWebsite);
        default:
          if (dec.toString().toLowerCase().startsWith('consultant')) {
            return 'Hidden decision';
          }
        //  stringDec = '';
        //  break;
      }
    }
    return stringDec;
  }

  // private decisionToString(decision: Decision): string {
  //   let date = (decision.when?.toLocaleString('en-US')??'').replace('T', ' ');
  //   date = date.substring(0, date.lastIndexOf('.'));
  //   const dec = this.decisionWhatToString(decision);
  //   return date + ' - ' + (decision.who?.displayName??'') + (dec !== '' ? (' - ' + dec) : '');
  // }

  private displayProjectName(projectId: string | undefined): string {
    if (!projectId) { return ''; }
    return projectId.projectName();
  }

  private displayProductName(productId: string | undefined): string {
    if (!productId) { return ''; }
    return productId.productName();
  }

  private iniString(
    initiativeType: IniType,
    decision: Decision, segment: (StringKey | null),
    initiativeKey: InitiativeKey,
    isSetup: boolean = false): string {
    switch(initiativeType) {
      case IniType.DMTool:
        {
          const stringDec: string = decision.what?.boolValue? this.stringService.strings[StringKey.DecisionsStringToolTaken]
            : this.stringService.strings[StringKey.DecisionsStringToolRemoved];
          if (segment !== null) {
            return stringDec.replace('{0}', this.displayProductName(decision.what?.productId)??'?').replace('{1}', this.stringService.initiatives[initiativeKey].value??'')
            .replace('{2}', this.stringService.strings[segment]).replace('{3}', (decision.what?.intValue??0).toString());
          }
          return '?';
        }
      case IniType.CompanyLevel:
        if (isSetup) {
          const stringDec: string = decision.what?.boolValue? this.stringService.strings[StringKey.DecisionsStringCompanyLevelInitiativeSetupMade] :
            this.stringService.strings[StringKey.DecisionsStringCompanyLevelInitiativeSetupCanceled];
          return stringDec.replace('{0}', this.stringService.initiatives[initiativeKey].value??'?');
        } else {
          const stringDec: string = decision.what?.boolValue? this.stringService.strings[StringKey.DecisionsStringCompanyLevelInitiativeTaken] :
            this.stringService.strings[StringKey.DecisionsStringCompanyLevelInitiativeCanceled];
          return stringDec.replace('{0}', this.stringService.initiatives[initiativeKey].value??'?');
        }
      case IniType.BrandLevel:
        if (isSetup) {
          const stringDec: string = decision.what?.boolValue? this.stringService.strings[StringKey.DecisionsStringBrandLevelInitiativeSetupMade] :
            this.stringService.strings[StringKey.DecisionsStringBrandLevelInitiativeSetupCanceled];
          return stringDec.replace('{0}', this.stringService.initiatives[initiativeKey].value??'?');
        } else {
          const stringDec: string = decision.what?.boolValue? this.stringService.strings[StringKey.DecisionsStringBrandLevelInitiativeTaken] :
            this.stringService.strings[StringKey.DecisionsStringBrandLevelInitiativeCanceled];
          return stringDec.replace('{0}', this.displayProductName(decision.what?.productId)??'?')
            .replace('{1}', this.stringService.initiatives[initiativeKey].value??'?');
        }
      case IniType.Leadership:
        {
          const stringDec: string = decision.what?.boolValue? this.stringService.strings[StringKey.DecisionsStringLeadIniTaken]
            : this.stringService.strings[StringKey.DecisionsStringLeadIniCanceled];
          return stringDec.replace('{0}', this.stringService.initiatives[initiativeKey].value??'?');
        }
    }
  }

  private truncate(str: string | undefined): string{
    if (!str) { return ''; }
    const maxLength = 50;
    if (str.length <= maxLength) { return str; }
    const subString = str.slice(0, maxLength-1); // the original check
    return subString.slice(0, subString.lastIndexOf(' ')) + '...';
  }

  private insightString(decision: Decision, insightKey: InsightKey): string {
    const stringDec: string = decision.what?.boolValue? this.stringService.strings[StringKey.DecisionsStringInsightOrdered]
      : this.stringService.strings[StringKey.DecisionsStringInsightCanceled];
    return stringDec.replace('{0}', this.truncate(this.stringService.insights[insightKey].observationTitle));
  }

  private marketStudyString(decision: Decision, marketStudyKey: StringKey): string {
    const stringDec: string = decision.what?.boolValue? this.stringService.strings[StringKey.DecisionsStringMarketStudyTaken]
     : this.stringService.strings[StringKey.DecisionsStringMarketStudyCanceled];
    return stringDec
      .replace('{0}', this.stringService.strings[marketStudyKey])
      .replace('{1}', this.stringService.strings[decision.what?.market??'']);
  }

  private commSegmentString(decision: Decision, segment: StringKey): string {
    return this.stringService.strings[StringKey.DecisionsStringCommShareSegment]
      .replace('{0}', this.displayProductName(decision.what?.productId)??'?').replace('{1}', this.stringService.strings[segment])
      .replace('{2}', (decision.what?.intValue??0).toString());
  }

  private salesForcesString(decision: Decision, channel: StringKey, isDelta: boolean): string {
    if (isDelta) {
      return this.stringService.strings[StringKey.DecisionsStringSalesForcesDelta]
        .replace('{0}', this.displayProductName(decision.what?.productId)??'?').replace('{1}', this.stringService.strings[channel])
        .replace('{2}', (decision.what?.intValue??0).toString());
    } else {
      return this.stringService.strings[StringKey.DecisionsStringSalesForces]
      .replace('{0}', this.displayProductName(decision.what?.productId)??'?').replace('{1}', this.stringService.strings[channel])
      .replace('{2}', (decision.what?.intValue??0).toString());
    }
  }

  private commPerceptualString(decision: Decision, mdsOrSem: StringKey): string {
    return this.stringService.strings[StringKey.DecisionsStringPerceptualValue]
      .replace('{0}', this.displayProductName(decision.what?.productId)??'?').replace('{1}', this.stringService.strings[mdsOrSem])
      .replace('{2}', (decision.what?.intValue??0).toString());
  }
}
