import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Initiative, Insight, Mail, MenuEntry, StringValue } from 'src/app/generated/model/scenario';
import { SimulationType } from 'src/app/generated/model/seminar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StringApi {

  private readonly bn: string;

  constructor(
    private http: HttpClient) {
      this.bn = environment.formatBuildNumber(environment.buildNumber);
    }

  public getInitiatives(type: SimulationType): Observable<Initiative[]> {
    return this.http.get<Initiative[]>(`./strings/${type??SimulationType.B2B}-Initiative.json?i=${this.bn}`);
  }

  public getInsights(type: SimulationType): Observable<Insight[]> {
    return this.http.get<Insight[]>(`./strings/${type??SimulationType.B2B}-Insight.json?i=${this.bn}`);
  }

  public getMails(type: SimulationType): Observable<Mail[]> {
    return this.http.get<Mail[]>(`./strings/${type??SimulationType.B2B}-Mailbox.json?i=${this.bn}`);
  }

  public getMenuEntries(type: SimulationType): Observable<MenuEntry[]> {
    return this.http.get<MenuEntry[]>(`./strings/${type??SimulationType.B2B}-Menu.json?i=${this.bn}`);
  }

  public getStringValues(type: SimulationType): Observable<StringValue[]> {
    return this.http.get<StringValue[]>(`./strings/${type??SimulationType.B2B}-StringValue.json?i=${this.bn}`);
  }
}
