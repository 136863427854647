import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { ScenarioApi } from 'src/app/api/scenario/scenario-api';
import { ScenarioDescription } from 'src/app/generated/model/scenario';
import { DictionaryOf } from 'src/app/model';
import { LogService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class DashboardScenarioService {
  readonly componentName = 'DashboardScenarioService';
  private _initialized$: Subject<boolean>;
  private _scenarioDescriptionDict: DictionaryOf<ScenarioDescription> = {};
  private _scenarioDescriptions: ScenarioDescription[] = [];
  private console = LogService.initialize(this.componentName);

  constructor(private scenarioApi: ScenarioApi,) {
    this._initialized$ = new ReplaySubject(1);
    this.scenarioApi.getScenariosDescriptions().subscribe(scenarioDescriptions => {
      this.console.log(`getScenariosDescriptions`, scenarioDescriptions);

      this._scenarioDescriptions = scenarioDescriptions;
      scenarioDescriptions.forEach(x => {
        this._scenarioDescriptionDict[x.id] = x;
      });

      this.console.log(`INITIALIZED`);
      this._initialized$.next(true);
      this._initialized$.complete();
    });
   }

   public get initialized$(): Subject<boolean> {
    return this._initialized$;
  }

  public get scenarioDescriptionDict(): DictionaryOf<ScenarioDescription> {
    return this._scenarioDescriptionDict;
  }

  public get scenarioDescriptions(): ScenarioDescription[] {
    return this._scenarioDescriptions;
  }
}
