/**
 * CentrXScenario.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable max-len */


export type InitiativeKey = 'CorpIni_BigDataAnalytics' | 'CorpIni_ClosedLoop' | 'CorpIni_CoDevelopmentCustomers' | 'CorpIni_CrossFunctional' | 'CorpIni_CrowdSourcing' | 'CorpIni_CustomerCentricity' | 'CorpIni_HighGrowth' | 'CorpIni_Iso14001' | 'CorpIni_Recycling' | 'CorpIni_SixSigmaProgram' | 'CorpIni_SponsorMeetings' | 'CustIni_CoBranding' | 'CustIni_CoDevelopment' | 'CustIni_CustomerDiscovery' | 'CustIni_DistributorsIncentives' | 'CustIni_GuaranteedDates' | 'CustIni_ImproveITSystem' | 'CustIni_LeadUsersGroup' | 'CustIni_NewInstallation' | 'CustIni_PathwayGuide' | 'CustIni_RemoteMonitoring' | 'CustIni_UseCase' | 'CustIni_CircularManufacturing' | 'DI_CustomerExperienceJourney' | 'DI_CustomerValue' | 'DI_StakeholderDiscovery' | 'DI_ValueChain' | 'DMTool_ChannelSupport' | 'DMTool_Content' | 'DMTool_Email' | 'DMTool_IndustryMicrosite' | 'DMTool_InteractiveDesign' | 'DMTool_OnlineCommunities' | 'DMTool_SEM' | 'DMTool_SocialMedia' | 'DMTool_SupportWebsite' | 'LeadIni_CashFlowInitiative' | 'LeadIni_CultureChange' | 'LeadIni_EmployeeEngagement' | 'LeadIni_FlexibleManufacturing' | 'LeadIni_LowCostManufacturing' | 'LeadIni_SalesTraining' | 'LeadIni_OptimalSemLoad' | 'IniCF_CashMgt' | 'IniCF_GrossMargin' | 'IniCF_InventoryMgt' | 'IniCorpRep_DefensiveStrategy' | 'IniCorpRep_OffensiveStrategy' | 'IniSoC_BiologicCampaign' | 'IniSoC_HECONCampaign' | 'IniTraining_SalesForce';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const InitiativeKey = {
    CorpIniBigDataAnalytics: 'CorpIni_BigDataAnalytics' as InitiativeKey,
    CorpIniClosedLoop: 'CorpIni_ClosedLoop' as InitiativeKey,
    CorpIniCoDevelopmentCustomers: 'CorpIni_CoDevelopmentCustomers' as InitiativeKey,
    CorpIniCrossFunctional: 'CorpIni_CrossFunctional' as InitiativeKey,
    CorpIniCrowdSourcing: 'CorpIni_CrowdSourcing' as InitiativeKey,
    CorpIniCustomerCentricity: 'CorpIni_CustomerCentricity' as InitiativeKey,
    CorpIniHighGrowth: 'CorpIni_HighGrowth' as InitiativeKey,
    CorpIniIso14001: 'CorpIni_Iso14001' as InitiativeKey,
    CorpIniRecycling: 'CorpIni_Recycling' as InitiativeKey,
    CorpIniSixSigmaProgram: 'CorpIni_SixSigmaProgram' as InitiativeKey,
    CorpIniSponsorMeetings: 'CorpIni_SponsorMeetings' as InitiativeKey,
    CustIniCoBranding: 'CustIni_CoBranding' as InitiativeKey,
    CustIniCoDevelopment: 'CustIni_CoDevelopment' as InitiativeKey,
    CustIniCustomerDiscovery: 'CustIni_CustomerDiscovery' as InitiativeKey,
    CustIniDistributorsIncentives: 'CustIni_DistributorsIncentives' as InitiativeKey,
    CustIniGuaranteedDates: 'CustIni_GuaranteedDates' as InitiativeKey,
    CustIniImproveITSystem: 'CustIni_ImproveITSystem' as InitiativeKey,
    CustIniLeadUsersGroup: 'CustIni_LeadUsersGroup' as InitiativeKey,
    CustIniNewInstallation: 'CustIni_NewInstallation' as InitiativeKey,
    CustIniPathwayGuide: 'CustIni_PathwayGuide' as InitiativeKey,
    CustIniRemoteMonitoring: 'CustIni_RemoteMonitoring' as InitiativeKey,
    CustIniUseCase: 'CustIni_UseCase' as InitiativeKey,
    CustIniCircularManufacturing: 'CustIni_CircularManufacturing' as InitiativeKey,
    DICustomerExperienceJourney: 'DI_CustomerExperienceJourney' as InitiativeKey,
    DICustomerValue: 'DI_CustomerValue' as InitiativeKey,
    DIStakeholderDiscovery: 'DI_StakeholderDiscovery' as InitiativeKey,
    DIValueChain: 'DI_ValueChain' as InitiativeKey,
    DMToolChannelSupport: 'DMTool_ChannelSupport' as InitiativeKey,
    DMToolContent: 'DMTool_Content' as InitiativeKey,
    DMToolEmail: 'DMTool_Email' as InitiativeKey,
    DMToolIndustryMicrosite: 'DMTool_IndustryMicrosite' as InitiativeKey,
    DMToolInteractiveDesign: 'DMTool_InteractiveDesign' as InitiativeKey,
    DMToolOnlineCommunities: 'DMTool_OnlineCommunities' as InitiativeKey,
    DMToolSEM: 'DMTool_SEM' as InitiativeKey,
    DMToolSocialMedia: 'DMTool_SocialMedia' as InitiativeKey,
    DMToolSupportWebsite: 'DMTool_SupportWebsite' as InitiativeKey,
    LeadIniCashFlowInitiative: 'LeadIni_CashFlowInitiative' as InitiativeKey,
    LeadIniCultureChange: 'LeadIni_CultureChange' as InitiativeKey,
    LeadIniEmployeeEngagement: 'LeadIni_EmployeeEngagement' as InitiativeKey,
    LeadIniFlexibleManufacturing: 'LeadIni_FlexibleManufacturing' as InitiativeKey,
    LeadIniLowCostManufacturing: 'LeadIni_LowCostManufacturing' as InitiativeKey,
    LeadIniSalesTraining: 'LeadIni_SalesTraining' as InitiativeKey,
    LeadIniOptimalSemLoad: 'LeadIni_OptimalSemLoad' as InitiativeKey,
    IniCFCashMgt: 'IniCF_CashMgt' as InitiativeKey,
    IniCFGrossMargin: 'IniCF_GrossMargin' as InitiativeKey,
    IniCFInventoryMgt: 'IniCF_InventoryMgt' as InitiativeKey,
    IniCorpRepDefensiveStrategy: 'IniCorpRep_DefensiveStrategy' as InitiativeKey,
    IniCorpRepOffensiveStrategy: 'IniCorpRep_OffensiveStrategy' as InitiativeKey,
    IniSoCBiologicCampaign: 'IniSoC_BiologicCampaign' as InitiativeKey,
    IniSoCHECONCampaign: 'IniSoC_HECONCampaign' as InitiativeKey,
    IniTrainingSalesForce: 'IniTraining_SalesForce' as InitiativeKey
};