
// eslint-disable-next-line id-blacklist
interface String {
  productName(): string;
  projectName(): string;
  capitalize(): string;
  toZString(): string;
}

String.prototype.productName = function (): string {
  const index = this.indexOf('_');
  return index !== -1 ? this.substring(0, index) : this as string;
};

String.prototype.projectName = function (): string {
  const index = this.indexOf('_');
  return index !== -1 ? this.substring(0, index) : this as string;
};


String.prototype.capitalize = function (): string {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.toZString = function (): string {
  const zdate = this.replace(/Z$/, '') + 'Z';
  return zdate;
};
