/**
 * CentrXScenario.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable max-len */


export type PriceCalculationMethod = 'InflationRate' | 'InflationRatePreviousPeriod' | 'FixedPrice';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PriceCalculationMethod = {
    InflationRate: 'InflationRate' as PriceCalculationMethod,
    InflationRatePreviousPeriod: 'InflationRatePreviousPeriod' as PriceCalculationMethod,
    FixedPrice: 'FixedPrice' as PriceCalculationMethod
};