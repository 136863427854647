/**
 * CentrXScenario.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable max-len */


export type ActualSource = 'CEJ_Consider' | 'CEJ_Purchase' | 'CEJ_Recycle' | 'CEJ_Research' | 'CEJ_Use' | 'CS_CustomersCustomers' | 'CS_Distributors' | 'CV_Value' | 'SD_EconomicBuyer' | 'SD_EndUser' | 'SD_TechnicalBuyer';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ActualSource = {
    CEJConsider: 'CEJ_Consider' as ActualSource,
    CEJPurchase: 'CEJ_Purchase' as ActualSource,
    CEJRecycle: 'CEJ_Recycle' as ActualSource,
    CEJResearch: 'CEJ_Research' as ActualSource,
    CEJUse: 'CEJ_Use' as ActualSource,
    CSCustomersCustomers: 'CS_CustomersCustomers' as ActualSource,
    CSDistributors: 'CS_Distributors' as ActualSource,
    CVValue: 'CV_Value' as ActualSource,
    SDEconomicBuyer: 'SD_EconomicBuyer' as ActualSource,
    SDEndUser: 'SD_EndUser' as ActualSource,
    SDTechnicalBuyer: 'SD_TechnicalBuyer' as ActualSource
};