import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { SubMenuItem } from '../model';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  readonly componentName = 'FavoriteService';
  readonly KEY = `${this.componentName}.favoriteListKey`;

  private _favoriteList: SubMenuItem[] = [];
  private _favoriteList$: Subject<SubMenuItem[]>;
  private initialized: boolean = false;

  private console = LogService.initialize(this.componentName);

  private favoriteListKey: string = '';

  constructor() {
    this.console.log(`constructor()`);
    this._favoriteList$ = new ReplaySubject(1);
  }

  public get favoriteList$(): Subject<SubMenuItem[]> {
    return this._favoriteList$;
  }

  public get favoriteList(): SubMenuItem[] {
    return this._favoriteList;
  }

  initList(appName: string, userId: string): void {
    this.favoriteListKey = this.KEY + userId + appName;

    this.initialized = true;
    if (this._favoriteList.length === 0) {
      const list = window.localStorage.getItem(this.favoriteListKey);
      if (list) {
        this._favoriteList = JSON.parse(list);
        this._favoriteList = this._favoriteList.filter(x => x.key !== undefined);
      }
    }

    this._favoriteList$.next(this._favoriteList);
  }

  addFavorite(favorite: SubMenuItem): void {
    if (favorite.key !== undefined) {
      this._favoriteList.push(favorite);
      window.localStorage.setItem(this.favoriteListKey, JSON.stringify(this._favoriteList));
      this._favoriteList$.next(this._favoriteList);
    }
  }

  removeFavorite(toRemove: SubMenuItem): void {
    this._favoriteList = this._favoriteList.filter(favorite => favorite.key !== toRemove.key);
    window.localStorage.setItem(this.favoriteListKey, JSON.stringify(this._favoriteList));
    this._favoriteList$.next(this._favoriteList);
  }
}
