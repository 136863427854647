/**
 * CentrXScenario.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable max-len */


export type ActivationMethod = 'Period' | 'Key' | 'PeriodKey' | 'PeriodKeyNextPeriod' | 'PeriodNbProduct' | 'PeriodNbProductKey';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ActivationMethod = {
    Period: 'Period' as ActivationMethod,
    Key: 'Key' as ActivationMethod,
    PeriodKey: 'PeriodKey' as ActivationMethod,
    PeriodKeyNextPeriod: 'PeriodKeyNextPeriod' as ActivationMethod,
    PeriodNbProduct: 'PeriodNbProduct' as ActivationMethod,
    PeriodNbProductKey: 'PeriodNbProductKey' as ActivationMethod
};