import { Injectable } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { Initiative, Insight, Mail } from 'src/app/generated/model/scenario';
import { MenuEntry, StringValue } from 'src/app/generated/model/scenario';
import { StringApi } from '../api/string/string-api';
import { IStringService } from '../interface/IStringService';
import { DictionaryOf } from '../model';
import { LogService } from './log.service';
import { SimulationType } from '../generated/model/seminar/simulationType';

@Injectable({
  providedIn: 'root'
})
export class StringService implements IStringService {

  // Default common strings
  static companyName: string = 'StratX ExL';
  static companyDomain: string = 'stratx-exl';
  static brandName: string = 'CentrX';

  private _initialized$: Subject<boolean>;
  private _initializedForSim$: DictionaryOf<Subject<boolean>>;
  private _initStartedForSim: DictionaryOf<boolean>;

  private _strings: DictionaryOf<DictionaryOf<string>> = {};
  private _initiatives: DictionaryOf<DictionaryOf<Initiative>> = {};
  private _menuEntries: DictionaryOf<DictionaryOf<MenuEntry>> = {};
  private _insights: DictionaryOf<DictionaryOf<Insight>> = {};

  private _stringValues: DictionaryOf<StringValue[]> = {};
  private _initiativeValues: DictionaryOf<Initiative[]> = {};
  private _insightValues: DictionaryOf<Insight[]> = {};
  private _mails: DictionaryOf<Mail[]> = {};
  private _menuEntryValues: DictionaryOf<MenuEntry[]> = {};

  private _defaultType: SimulationType = SimulationType.B2B;

  private console = LogService.initialize('StringService');

  constructor(private stringApi: StringApi) {
    this._initializedForSim$ = {};
    this._initialized$ = new ReplaySubject<boolean>(1);
    this._initStartedForSim = {};

    Object.values(SimulationType).forEach(value => {
      this._initializedForSim$[value] = new ReplaySubject<boolean>(1);
      this._initStartedForSim[value] = false;
      this._stringValues[value] = [];
      this._initiativeValues[value] = [];
      this._insightValues[value] = [];
      this._mails[value] = [];
      this._menuEntryValues[value] = [];
      this._strings[value] = {};
      this._initiatives[value] = {};
      this._menuEntries[value] = {};
      this._insights[value] = {};
    });

    this.getStringsForSim(this._defaultType);
  }

  public get initialized$(): Subject<boolean> {
    return this._initialized$;
  }

  public get strings(): DictionaryOf<string> {
    return this._strings[this._defaultType];
  }

  public get mails(): Mail[] {
    return this._mails[this._defaultType];
  }

  public get initiatives(): DictionaryOf<Initiative> {
    return this._initiatives[this._defaultType];
  }

  public get insights(): DictionaryOf<Insight> {
    return this._insights[this._defaultType];
  }

  public get menuEntries(): DictionaryOf<MenuEntry> {
    return this._menuEntries[this._defaultType];
  }

  public getStrings(simulationType: SimulationType | undefined): DictionaryOf<string> {
    return this._strings[simulationType ?? this._defaultType];
  }

  public getMails(simulationType: SimulationType | undefined = undefined): Mail[] {
    return this._mails[simulationType ?? this._defaultType];
  }

  public getInitiatives(simulationType: SimulationType | undefined = undefined): DictionaryOf<Initiative> {
    return this._initiatives[simulationType ?? this._defaultType];
  }

  public getInsights(simulationType: SimulationType | undefined = undefined): DictionaryOf<Insight> {
    return this._insights[simulationType ?? this._defaultType];
  }

  public getMenuEntries(simulationType: SimulationType | undefined = undefined): DictionaryOf<MenuEntry> {
    return this._menuEntries[simulationType ?? this._defaultType];
  }

  public initializedForSim$(type: SimulationType): Subject<boolean> {
    if (!this._initStartedForSim[type]) {
      this.getStringsForSim(type);
    }
    return this._initializedForSim$[type];
  }

  private flatten(menuEntries: MenuEntry[], value: string): void {
    menuEntries.forEach(entry => {
      if (entry) {
        if (entry.key && entry.value) {
          this._menuEntries[value][entry.key] = entry;
        }
        if (entry.subMenuEntries) {
          this.flatten(entry.subMenuEntries, value);
        }
      }
    });
  }

  private getStringsForSim(simulationType: SimulationType): void {
    this._initStartedForSim[simulationType] = true;
    combineLatest([
      this.stringApi.getStringValues(simulationType),
      this.stringApi.getInitiatives(simulationType),
      this.stringApi.getInsights(simulationType),
      this.stringApi.getMails(simulationType),
      this.stringApi.getMenuEntries(simulationType)]).subscribe(([stringValues, initiatives, insights, mails, menuEntries]) => {

        this._stringValues[simulationType] = stringValues.map(p => ({
          name: p.name,
          value: this.replaceFields(p.value),
        }));

        this._stringValues[simulationType].forEach(s => {
          if (s && s.name && s.value) {
            this._strings[simulationType][s.name] = s.value;
          }
        });

        this._initiativeValues[simulationType] = initiatives;
        this._initiativeValues[simulationType].forEach(s => {
          if (s && s.key) {
            this._initiatives[simulationType][s.key] = s;
          }
        });

        this._insightValues[simulationType] = insights;
        this._insightValues[simulationType].forEach(s => {
          if (s && s.key) {
            this._insights[simulationType][s.key] = s;
          }
        });

        this._mails[simulationType] = mails.map(p => ({
            key: p.key,
            content: this.replaceFields(p.content),
            sender: this.replaceFields(p.sender),
            subject: this.replaceFields(p.subject),
          }));

        this._menuEntryValues[simulationType] = this.replaceFieldsMenuEntries(menuEntries);

        this.flatten(menuEntries, simulationType);

        this.console.log(`STRINGS INITIALIZED for ${simulationType}`);
        this._initializedForSim$[simulationType].next(true);
        this._initializedForSim$[simulationType].complete();

        if (simulationType === this._defaultType) {
          this._initialized$.next(true);
          this._initialized$.complete();
        }
      });
  }

  private replaceFields(input: string | undefined): string | undefined {
      return input
        ?.replaceAll('{CompanyName}', StringService.companyName)
        ?.replaceAll('{BrandName}', StringService.brandName);
  }

  private replaceFieldsMenuEntries(input: MenuEntry[]): MenuEntry[] {
    return input.map(p => ({
      key: p.key,
      longTitle: this.replaceFields(p.longTitle),
      shortTitle: this.replaceFields(p.shortTitle),
      prefix: this.replaceFields(p.prefix),
      tooltip: this.replaceFields(p.tooltip),
      value: this.replaceFields(p.value),
      subMenuEntries: (p.subMenuEntries? this.replaceFieldsMenuEntries(p.subMenuEntries) : p.subMenuEntries) as Array<MenuEntry>,
    })) as MenuEntry[];
  }
}
